/* eslint-disable no-plusplus,no-restricted-syntax */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react'
import { LOCALES } from 'src/Constants/Locales'
import { LocaleDataType, UrlLocaleDataType } from 'src/Types/UrlLocaleDataType'
import { pushToDL } from './GTM'
import {
  AUTH_COOKIES,
  CHAR_LIMIT,
  MESSAGES,
  REGEX,
  XAR_STATE,
  EMBARGO_SCRIPT,
  RUM_CONFIG
} from '../Constants/Constants'

export const getQueryString = (queryString = null) => {
  const q = new URLSearchParams(queryString || window.location.search)

  if (!q) {
    return false
  }

  return Object.fromEntries(q.entries())
}

export const submitWithCaptcha = async (loginCB: Function) => {
  if (window.hcaptcha) {
    const token = window.hcaptcha.getResponse()
    await loginCB(token)
    window.hcaptcha.reset()
    return
  }

  if (window.grecaptcha) {
    return window.grecaptcha.ready(() => {
      window.grecaptcha.reset()
      window.grecaptcha.execute().then((value: any) => {
        if (value) {
          loginCB(value)
        }
      })
    })
  }

  throw new Error('captcha not loaded')
}

export const includeEmbargoScript = () => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = EMBARGO_SCRIPT
  document.head.appendChild(script)
}

export const objectize = (obj: any) => JSON.parse(JSON.stringify(obj))

export const getLocale = (): UrlLocaleDataType => {
  const { locale, supportedLocale, supportedForwardLocale } = LOCALES
  const urlParse = window.location.pathname.split('/')
  const receivedLocale = supportedLocale.includes(urlParse[1])
    ? urlParse[1]
    : 'en_us'
  const receivedForwardLocale = supportedForwardLocale.includes(urlParse[1])
    ? urlParse[1]
    : 'en_us'
  const localeData = locale[receivedLocale] || LOCALES.locale.en_us

  return { localeData, receivedLocale, receivedForwardLocale }
}

export const localeFooterLinks = (locale: LocaleDataType, received: string) => {
  const { PRIVACY, WEBSITE_TERMS, SPLUNK_LICENSING_TERMS, EXPORT_CONTROL } =
    locale

  const arr: any = {
    item1: {
      target: '_self',
      class: 'footer-link',
      name: PRIVACY,
      link: `https://www.splunk.com/${received}/legal/privacy/privacy-policy.html`
    },
    item2: {
      target: '_self',
      class: 'footer-link',
      name: WEBSITE_TERMS,
      link: `https://www.splunk.com/${received}/legal/terms/terms-of-use.html`
    },
    item3: {
      target: '_self',
      class: 'footer-link',
      name: SPLUNK_LICENSING_TERMS,
      link: `https://www.splunk.com/${received}/legal/splunk-terms-overview.html`
    },
    item4: {
      target: '_self',
      class: 'footer-link',
      name: EXPORT_CONTROL,
      link: `https://www.splunk.com/${received}/legal/export-controls.html`
    }
  }
  const d = document.querySelectorAll('.footer-content a')
  Object.keys(arr).forEach((num: string, index: number) => {
    const i = d[index] as HTMLAnchorElement

    if (i) {
      i.innerText = arr[num].name
      i.href = arr[num].link
      i.target = arr[num].target
      i.className = arr[num].class
    }
  })
}

export const cleanCookie = (name: string) => {
  document.cookie = `${name}= expires=Thu, 01 Jan 1970 00:00:00 UTC path=/`
}

export const invalidFormat = (email: string) => {
  if (!email || !email.length) {
    return false
  }
  return (
    email.length > CHAR_LIMIT.email.inputCharLimit || !REGEX.email.test(email)
  )
}

export const isValidEmail = (email: string) => {
  if (!email || !email.trim().length) {
    return false
  }
  const e = email.trim()

  return !invalidFormat(e)
}

export const getEmailValidationMessage = (email: string | undefined | null) => {
  if (!email || email === '' || !isValidEmail(email)) {
    return {
      message: 'invalidEmail',
      dlMessage: 'Invalid email entered'
    }
  }

  if (email.includes('@splunk.com')) {
    return {
      message: 'employeeRetrieveUsername',
      dlMessage: 'Employee email entered'
    }
  }

  return false
}

export const addLinkEventsToDL = (
  e: any,
  category: string,
  container: string,
  componentName: string
) => {
  const { value, closest } = e.target
  const clickText = value.replace(/\s/g, '')
  const { href } = closest('a')
  const linkURL = new URL(href)

  pushToDL('link_click', {
    linkText: value,
    linkDomain: linkURL.host,
    linkUrl: linkURL.host + linkURL.pathname,
    linkPosition: container
  })
  pushToDL('linkClick', {
    category,
    component: `${container}_${clickText}_${componentName}`,
    clickUrl: window.location.host,
    destinationUrl: linkURL.host + linkURL.pathname,
    container,
    componentName
  })

  window.location.href = href
}

export const readCookie = (name: string) => {
  const nameEQ = `${name}=`
  const ca = document.cookie.split(';')

  for (const cookie of ca) {
    const c = cookie.trim()

    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length).trim()
    }
  }

  return null
}

export const deletAuthCookies = (locationUrl: string) => {
  const clearDate = 'Thu, 01 Jan 1970 00:00:01 GMT'
  const { location } = window
  const cookieDomain = location.hostname.substring(
    location.hostname.indexOf('.')
  )
  AUTH_COOKIES.forEach((cookie: string) => {
    document.cookie = `${cookie}=; expires=${clearDate}; path=/; domain=${cookieDomain}`
  })

  document.cookie = `Apache=; expires=${clearDate}; path=/`
  document.cookie = `xar.sid=; expires=${clearDate}; path=/`
  document.cookie = `sid=; expires=${clearDate}; path=/; domain=${cookieDomain}`
  document.cookie = `sails.sid=; expires=${clearDate}; path=/; domain=account${cookieDomain}`

  sessionStorage.clear()
  localStorage.clear()

  location.replace(locationUrl)
}

export const passwordMeetsLengthRequirements = (password: string) => {
  if (password.length >= 8 && password.length <= 64) {
    return true
  }
  return false
}

export const passwordHasOneUpperAndOneLowercaseLetter = (password: string) => {
  return (
    REGEX.atLeastOneUppercase.test(password) &&
    REGEX.atLeastOneLowercase.test(password)
  )
}

export const passwordIncludesNumber = (password: string) => {
  const match = password.match(/\d/)
  return match !== null
}

export const passwordIncludesSpecialCharacter = (password: string) => {
  return REGEX.atLeastOneSpecialChar.test(password)
}

export const meetsAllRequirements = (password: string) => {
  if (
    password &&
    passwordMeetsLengthRequirements(password) &&
    passwordHasOneUpperAndOneLowercaseLetter(password) &&
    passwordIncludesNumber(password) &&
    passwordIncludesSpecialCharacter(password)
  ) {
    return true
  }
  return false
}

export const getTokenFromURL = () => {
  const query = new URLSearchParams(window.location.search)
  if (query.has('token')) {
    return query.get('token')
  }

  return false
}

export const getLostPasswordMessage = (email: string, data: any): string => {
  let key
  switch (true) {
    case !email || email.trim().length === 0:
      key = 'emptyEmailLostPassword'
      break
    case email && email.includes('@splunk.com'):
      key = 'employeeResetPassword'
      break
    case !data:
      key = 'resetPasswordFailure'
      break
    case data.userType === 'activeUser':
      key = 'lostPasswordEmailSent'
      break
    case data.userType === 'inactiveUser':
    case data.is_blacklisted:
    case data.xar_state === XAR_STATE.INACTIVE:
      key = 'inactiveUser'
      break
    case data.userType === 'lockedOutUser':
      key = 'lockedOutUser'
      break
    case data && data.xar_state === XAR_STATE.NOT_VALIDATED:
      key = 'notValidatedUser'
      break
    case data.userType === 'invalidEmail':
      key = 'invalidEmail'
      break
    default:
      key = 'activeUser'
  }

  return MESSAGES[key]
}

export const startRUM = () => {
  const { RUM_ENV, RUM_TOKEN, RUM_URL, RUM_APP } = RUM_CONFIG
  const { SplunkRum } = window
  if (SplunkRum && SplunkRum.init && !SplunkRum.inited) {
    SplunkRum.init({
      beaconUrl: RUM_URL,
      rumAuth: RUM_TOKEN,
      app: RUM_APP,
      environment: RUM_ENV
    })
  }
}

export const setCopyrightText = () => {
  const domObject = document.querySelector('.copyright-content > p')
  if (domObject) {
    const year = new Date().getFullYear()
    domObject.innerHTML = `© 2005 - ${year} Splunk Inc. All rights reserved.`
  }
}
